import React, {useEffect,useState} from 'react';

// plug-ins
import InputMask from 'react-input-mask';

// components
import {useInput} from '../../Components/Hooks/Input';
import Alert,{errorShow} from '../../Components/Alert';

// models
import {Partners} from '../../Models/';

// helpers
import Utils from '../../Globals/Utils';

// constants
import { API } from '../../Globals/Constants';

// styles
import './styles.css';

// start
const StartScreen = () => {
	const [loading, setLoading] = useState(true);
	const {value: phone, setValue:setPhone, bind: bindPhone} = useInput('');
	const {value: email, setValue:setEmail, bind: bindEmail} = useInput('');
	const {value: password, bind: bindPassword} = useInput('');
	useEffect(async () => {
		// setEmail('psh_@mail.ru');
		// setPhone('+7 (999) 999-99-90');
		setLoading(false);
	}, []);
	const scrollToRegister = () => {
		const element = document.getElementById('register');
		if (element) element.scrollIntoView({behavior:'smooth'});
	};
	const save = async (e) => {
		e.preventDefault();
		if (Utils.empty(email)) return errorShow('Необходимо заполнить электронную почту');
		if (Utils.empty(password)) return errorShow('Необходимо заполнить пароль');
		if (password.length < 8) return errorShow('Пароль должен быть не менее 8 символов');
		const res = await Partners.register({email,phone:Utils.phoneNormalize(phone),password}).catch((ex) => {
			console.log(666, ex);
			errorShow(ex.message);
		});
		if (!res) return;
		window.location.href = `${API.setup}/${res.url}`;
	};
	return loading ? null :
		<>
			<section className="home">
				<div className="home-content">
					<h1>Готовый сайт для доставки еды <sup>*</sup></h1>
					<p>
						Первые две недели — бесплатно,<br/> далее от 2 500 рублей в месяц
						<span><sup>*</sup> предоставляется система управления, сайт доставки, мобильное приложение</span>
					</p>
					<div>
						<button type="button" onClick={() => scrollToRegister()} className="button">Попробовать бесплатно</button>
					</div>
				</div>
			</section>
			<section id="register" className="registration">
				<h2>Регистрация</h2>
				<form onSubmit={save}>
					<input type="email" {...bindEmail} placeholder="Электронная почта" pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' required />
					<InputMask type="tel" mask="+7 (999) 999-99-99" placeholder="Телефон (не обязательно)" maskPlaceholder={null} {...bindPhone} />
					<input type="password" {...bindPassword} placeholder="Пароль (минимум 8 символов)" required />
					<button type="submit">Зарегистрироваться</button>
					<p>
						Регистрируясь, вы соглашаетесь с <a href="/politika-konfidencialnosti">политикой конфиденциальности</a> и принимаете условия <a href="/soglashenie">пользовательского соглашения</a>.
					</p>
				</form>
			</section>
			<footer className="footer">
				<ul className="footer-links">
					<li><a href="/politika-konfidencialnosti">Политика конфиденциальности</a></li>
					<li><a href="/soglashenie">Пользовательское соглашение</a></li>
					<li>&copy; PastilApp, 2024-2025</li>
				</ul>
			</footer>
			<Alert />
		</>;
};

export default StartScreen;